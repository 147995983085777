<template>
<div>
	<CRow>
		<CCol sm="12" md="12">
        <CCard>
          <CCardHeader>
            <CRow>
             <CCol col="9" class="text-left"><h4>TRS Report</h4></CCol>
            </CRow>
          </CCardHeader>
          <CCardBody>

           <CForm>

            	<CRow>
                <CCol col="12" class="text-left">
                		<CAlert v-bind:color="coloruser" v-if="seenuser" id="alertuser">{{ msguser }}</CAlert>
              	</CCol>
              </CRow>

              <div v-if="seenfile">
                  <b-row>
                    <b-col sm="3">
                       <label for="input-small" >Upload File </label>
                    </b-col>
                    <b-col sm="9">
                       <input type="file" :disabled="!isDisabledfile" @change="onFileChange" />
                    </b-col>
                  </b-row><br/>

                  <b-row>
                    <div class="table-responsive table">
                      <b-table striped hover  :items="items" :fields="fields"
                      :current-page="currentPage" :per-page="perPage" :filter="filter">

                      </b-table>
                    </div>
                  </b-row><br/>

                  <b-row>
                    <b-col sm="3">
                      <b-pagination size="md" :total-rows="this.items.length" :per-page="perPage" v-model="currentPage" />
                    </b-col>
                    <b-col sm="9">
                      <b-form-select :options="[{text:5,value:5},{text:10,value:10},{text:15,value:15}]" v-model="perPage" style="width:100px;">
                      </b-form-select>
                    </b-col>
                  </b-row>

              </div>



              <b-row>
                <b-col sm="4">
                    <br />
                    <b-row>
      								 <b-col sm="12">
      										<label for="input-small">Report Type</label>
      								 </b-col>
      								 <b-col sm="12">
      										<b-form-select v-model="reportType" :options="optReportType" @change="onReportTypeChange"  :disabled="seenCSVDwld"></b-form-select>
      								 </b-col>
      							</b-row>
                </b-col>
                <b-col sm="4" v-show="visibleReport">
                  <br />
                  <b-row>
    								 <b-col sm="12">
    										<label for="input-small">Account Type</label>
    								 </b-col>
    								 <b-col sm="12">
    										<b-form-select v-model="feature" :options="optFeatures"  :disabled="seenCSVDwld"></b-form-select>
    								 </b-col>
    							</b-row>
                </b-col>
                <b-col sm="4">
                  <br />
                  <b-row>
    								 <b-col sm="12">
    										<label for="input-small">Report Period</label>
    								 </b-col>
    								 <b-col sm="12">
    										<b-form-select v-model="dateType" :options="optDateType" @change="onDateTypeChange"  :disabled="seenCSVDwld"></b-form-select>
                        <small class="text-muted" v-show="visibleIntra">Intraday Report only extracts data within 30 minutes window. Eg: from 08:30 to 09:00 hours if 08:30 is set above.</small>
    								 </b-col>
    							</b-row>
                </b-col>
							  <b-col sm="4">
                  <br />
                  <b-row>
    								 <b-col :sm="visibleIntra ? '6' : '12'">
                        <label for="input-small">Date</label>
    										<CInput v-bind:type="dateInputType" v-model="date" v-bind:max="todayDate"  :disabled="seenCSVDwld" />
                     </b-col>
    								 <b-col v-show="visibleIntra" sm="3">
    										<label for="input-small">Hour</label>
                        <b-form-select v-show="visibleIntra" v-model="hour" :options="optHour"  :disabled="seenCSVDwld"></b-form-select>
    								 </b-col>
    								 <b-col v-show="visibleIntra" sm="3">
    										<label for="input-small">Minute</label>
                        <b-form-select v-show="visibleIntra" v-model="time" :options="optTime"  :disabled="seenCSVDwld"></b-form-select>
    								 </b-col>

    							</b-row>
                </b-col>
                <b-col sm="4" v-show="visibleReport">
                  <br />
                  <b-row>
                      <b-col sm="12">
                         <label for="input-small" >Currency</label>
                      </b-col>
                      <b-col sm="12">
                         <b-form-select v-model="currency" :options="optCurrency"  :disabled="seenCSVDwld"></b-form-select>
                       </b-col>
                  </b-row>
                </b-col>
              </b-row>

              <span v-if="seenOTP" >
                <CInput
                  type="text"
                  label="Tr Reference"
                  v-model="trRef"
                  :readonly="true"
                  horizontal
                />


              </span>

              <b-row>
                  <b-col sm="12">
                    <br />
                    <b-button-group>
                     <CButton color="primary" v-on:click="actionSubmit()" :disabled="seenCSVDwld">Submit</CButton>
                      &nbsp;
                     <CButton v-if="sent" v-on:click="actionResend()" color="success">Resend OTP</CButton>
                     &nbsp;
                     <vue-excel-xlsx
                        v-if="seenBtnDwld"
                        style="color:green"
                        :data="data"
                        :columns="columns"
                        :filename="'Batch-Credit-Report'"
                        :sheetname="'Batch-Credit-Report'"
                        >
                        Download Report
                     </vue-excel-xlsx>
                     <a v-bind:href="csvLink"
                        v-if="seenCSVDwld"
                        class="btn btn-success"
                        >
                        Download TRS Report
                     </a>
                     &nbsp;
                     <CButton v-if="seenCSVDwld" color="danger" v-on:click="actionReset()">Cancel</CButton>
                     </b-button-group>
                  </b-col>
                  </b-col>
              </b-row><br/>

              <b-row v-if="csvHasItem !== false">
                <div class="table-responsive table">
                  <b-table striped hover :items="csvItems" :fields="csvFields"
                  :current-page="currentPage" :per-page="perPage" :filter="filter">

                  </b-table>
                </div>
                <br />
                <div class="table-responsive table">
                  <b-table striped hover :items="csvItems2" :fields="csvFields2"
                  :current-page="currentPage" :per-page="perPage" :filter="filter">

                  </b-table>
                </div>
              </b-row><br v-if="csvHasItem !== false"/>

              <b-row v-if="pbar">
                  <b-col sm="12">
                   <b-progress :max="max" height="2rem" show-progress animated>
                    <b-progress-bar :value="value" variant="success" :label="`${((value / max) * 100)}%`"></b-progress-bar>
                  </b-progress>
                  </b-col>
              </b-row><br/>

              <div class="text-center">
                <b-spinner variant="success" label="Spinning" v-if="spinner" ></b-spinner>
              </div>

              <CRow>
                <CCol col="12" class="text-left">
                <CAlert v-bind:color="color" v-if="seen" id="alert">{{ msg }}</CAlert>
              </CCol>
              </CRow>

            </CForm>

           </CCardBody>
        </CCard>
      </CCol>

     </CRow>

</div>
</template>



<script>
 import AuthService from '@/api/AuthService.js';
 import VueTimepicker from 'vue2-timepicker';
 import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap';
 import readXlsxFile from 'read-excel-file';
 import moment from 'moment';
 import Papa from 'papaparse';
 import axios from 'axios';

  export default {
    name: 'trsreport',
    components: {
      VueTypeaheadBootstrap,
      VueTimepicker
    },
    data() {
			var newdate = new Date();
			return {
          trRef:'',
          todayDate: newdate.toISOString().substr(0, 10),
          todayMonth: newdate.toISOString().substr(0, 7),
          otp:'',
					checked: false,
					hiddenChecked : true,
          seenOTP:false,
          sent:false,
          csvHasItem:false,
          columns :[
            { label: "Phone Number ", field: "phoneNumber" },
            { label: "User ID ", field: "userId" },
            { label: "Currency", field: "currency"},
            { label: "Amount", field: "amount"},
            { label: "Datetime", field: "dateTime"},
            { label: "Status", field: "status"},
          ],
          optCustMsg:[
            {"value":1, "text" : "Top-up by ATM Cash Deposit"},
            {"value":2, "text" : "Top-up without top-up Code"},
            {"value":3, "text" : "Reward for Moolahgo Promotion"},
            {"value":4, "text" : "Reward for Identity Verification"},
            {"value":5, "text" : "Refund due to Debit error"},
            {"value":6, "text" : "Refund from Trx Cancellation"},
            {"value":7, "text" : "Others"},
          ],
          seenfile:false,
          cbbfile:false,
          isDisabledSlc: true,
          isDisabledfile:true,
          isDisabledcurr:true,
          isDisabledamount:true,
          isDisabledtrType:true,
          seenBtnDwld:false,
          seenCSVDwld:false,
          optCurrencyComplete: [
						{"value": "CNY", "text": "CNY"},
						{"value": "SGD", "text": "SGD"},
						{"value": "USD", "text": "USD"}
					],
          optCurrency: [
						{"value": "CNY", "text": "CNY"},
						{"value": "SGD", "text": "SGD"},
						{"value": "USD", "text": "USD"}
					],
          optNumber:[],
          optWallet:[],
          optHour:[],
          optTime:[
            {"value": "00", "text": "00"},
            {"value": "30", "text": "30"}
          ],
          optReportType:[
            {"value": "BALANCE_CHANGE", "text": "BALANCE CHANGE"},
            {"value": "REVERSAL", "text": "REVERSAL"},
            {"value": "TRANSACTION", "text": "TRANSACTION"}
          ],
          optDateType:[
            {"value": "END_OF_DAY", "text": "Daily"},
            {"value": "MONTHLY", "text": "Monthly"},
            {"value": "INTRA_DAY", "text": "Intra Day"}
          ],
          optFeatures:[
            {"value": "CAPITAL", "text": "CAPITAL"},
            {"value": "FEE", "text": "FEE"}
          ],
          optFeaturesComplete:[
            {"value": "CAPITAL", "text": "CAPITAL"},
            {"value": "FEE", "text": "FEE"}
          ],
          phoneNum:'',
          dateInputType:'date',
          baseItems:[],
          baseItems2:[],
          baseUserId:[],
          walletGroup:[],
					walletGroupAvailable:[],
          basePhoneNum:[],
          optTrType:[],
          reportList:[],
          report:[],
          successList:[],
          failedList:[],
          userId:'',
          displayName:'',
          date: newdate.toISOString().substr(0, 10),
          hour: '',
          time: '',
          expiredTime: moment.utc(newdate).local().format('HH:mm:ss'),
          walletId:'',
          trType:'',
          currency:'CNY',
          amount:'',
          reason:'',
          custMsg:'',
          otherMsg:'',
          feature:'CAPITAL',
          reportType:'BALANCE_CHANGE',
          dateType:'END_OF_DAY',
          csvLink:'',
          items:[],
          csvItems:[],
          csvItems2:[],
          csvData:{},
          fields: [
            {
              key: 'phoneNumber',
              label : 'Phone Number',
              sortable: true
            },
            {
              key: 'currency',
              label : 'Currency',
              sortable: true
            },
            {
              key: 'amount',
              label : 'Amount',
              sortable: true
            },
          ],
          csvFields: [],
          csvFields2: [],
          pbar:false,
          value:0,
          max:0,
          spinner:false,
          currentPage: 1,
          perPage: 5,
          filter: null,
          seenOther: false,
          visibleReport: true,
          visibleIntra: false,
          seen:false,
          msg :'',
          color:'',
          seenuser:false,
          msguser :'',
          coloruser:''
      };
    },

    created () {
        this.getConfigList();
        this.fetchUsers();
        this.getWalletGroupList();
    },

    methods: {

      actionResend(){
        this.spinner = true;
        this.debitCreditValidate();
      },

      onChangeCsMsg(){

        if(this.custMsg === 7){
           this.seenOther = true;
        } else { this.seenOther = false; }

      },

      getDateTime(){
        var today = new Date();
        var date = today.getFullYear()+'/'+(today.getMonth()+1)+'/'+today.getDate();
        var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        var dateTime = date+' '+time;
        return dateTime;
      },

      parseCSVByURL(url) {
          const corsProxyUrl = "https://proxy.cors.sh/"; // CORS proxy URL
          axios
          .get(corsProxyUrl + url, {
              headers: {
                'x-cors-api-key': 'temp_8150b6f661bdd370760b768386666ac7'
              }
          }).then((response) => {
              // Process the CSV data
              // You can use PapaParse or any other CSV parsing library here
              // For example, using PapaParse:
              const Papa = require("papaparse");
              const parsedData = Papa.parse(response.data, {
                header: true,
                skipEmptyLines: false,
                delimiter: "\r\n"
              });
              this.csvData = {
                headers: Object.keys(parsedData.data[0]),
                rows: parsedData.data,
              };
              // console.log(this.csvData);
              this.spinner = false;

              var firstItems = [];
              var newHeaders = [];
              var secondItems = [];
              var newHeaders2 = [];
              if (parsedData.hasOwnProperty('data') && parsedData.data.length > 0) {
                  this.csvHasItem = true;
                  var firstheaderkey = Object.keys(parsedData.data[0])[0];
                  var firstitem = parsedData.data[0][firstheaderkey].split(",");
                  var firstheader = firstheaderkey.split(",");
                  let rows = {};
                  for (var itemp in firstheader) {
                      var keyes = firstheader[itemp];
                      var newHeads = {
                        'key': keyes,
                        'label': keyes,
                        'sortable': false
                      };
                      newHeaders.push(newHeads);
                      var values = firstitem[itemp];
                      rows[keyes] = values;
                  }
                  this.csvFields = newHeaders;
                  // console.log(rows);
                  firstItems.push(rows);
                  // console.log(firstItems);
                  this.csvItems = firstItems;
                  // console.log(firstheader);
                  // console.log(firstitem);
                  var seconditem = parsedData.data.slice(0);
                  var relSecond = seconditem.slice(1);
                  // console.log(relSecond);
                  var secondheader = relSecond[0][firstheaderkey].split(",");
                  if (relSecond.length > 0) {
                      for (var itemp2 in relSecond) {
                          if(itemp2 > 0) {
                              var rowz = relSecond[itemp2];
                              var rowarr = rowz[firstheaderkey].split(",");
                              let rowzy = {}
                              for (var itemp3 in rowarr) {
                                  var newHeads2 = {
                                    'key': secondheader[itemp3],
                                    'label': secondheader[itemp3],
                                    'sortable': false
                                  };
                                  newHeaders2.push(newHeads2);
                                  rowzy[secondheader[itemp3]] = rowarr[itemp3];
                              }
                              secondItems.push(rowzy);
                          }
                      }
                      this.csvFields2 = newHeaders2;
                      this.csvItems2 = secondItems;
                      // console.log(secondItems);
                  }
              } else {
                  this.csvHasItem = false;
                  this.csvItems = [];
              }
          }).catch((error) => {
              console.error("Error fetching CSV data:", error);
          });
      },

      findUserId(phoneNum){
        var basePhoneNum = this.basePhoneNum;   var baseUserId = this.baseUserId;
        let index    = basePhoneNum.findIndex(basePhoneNum => basePhoneNum === phoneNum );
        var userId   = baseUserId[index];
        //console.log(index+"|"+ phoneNum +"=="+ userId);
        return userId;
      },

      async actionSubmit(){
            let param = {};
            param["accountType"]   = this.feature;
            param["currency"] = this.currency;
            if (this.dateType == "MONTHLY") {
                var seldate = new Date(this.date).toISOString().substr(0, 7);
            } else {
                var seldate = new Date(this.date).toISOString().substr(0, 10);
                if (this.dateType == "INTRA_DAY") {
                    seldate = seldate + this.hour + this.time;
                }
            }
            param["date"] = seldate.split("-").join("");
            param["dateType"] = this.dateType;
            param["reportType"] = this.reportType;
            this.spinner=true;
            this.seenCSVDwld = false;
            AuthService.getReportTRS(param).then(resp => {
                if (resp.statusCode == "S0000") {
                    if (resp.payload.hasOwnProperty('bill_address')) {
                        this.csvLink = resp.payload.bill_address;
                        this.seenCSVDwld = true;
                        this.spinner = false;
                        this.csvHasItem = false;
                        // this.parseCSVByURL(resp.payload.bill_address);
                    } else {
                        this.spinner = false;
                        this.seenCSVDwld = false;
                        this.csvLink = "";
                        this.csvHasItem = false;
                        this.msg   = resp.payload.return_code + " : " + resp.payload.return_msg;
                        this.color = 'warning'; this.seen  = true;
                        setTimeout( () =>  this.seen=false , 5000);
                    }
                }
            }, error => {
                console.log('error');
            });
      },

      completeBatchCredit(){
          setTimeout( () =>  this.pbar=false , 5000);
          this.spinner = false;
          this.msg   = "Batch Credit Completed!";
          this.color = 'success';  this.seen=true;
          setTimeout( () => this.seen=false, 5000);
          this.actionReset();
          //console.log(this.reportList);
          this.report = this.reportList;
          this.data   = this.report;
          this.seenBtnDwld = true;
					this.isDisabledcurr = true;
					this.isDisabledamount = true;
					this.checked = false;
					this.hiddenChecked = true;
          this.reportList = [];

      },

      completeSingleCredit(){
          this.msg   = 'Credit user successfully done';
          this.color = 'success';  this.seen  = true;
          setTimeout( () => this.seen=false, 5000);
          this.phoneNum='';
          this.userId='';
          this.displayName='';
          this.trType = '';
          this.walletId='';
          this.currency='';
          this.amount='';
          this.custMsg='';
          this.otherMsg='';
          this.reason='';
          this.trRef='';
					this.checked = false;
					this.hiddenChecked = true;
          this.otp = '';
          this.seenOTP = false;
          this.sent = false;
      },

      actionReset(){
          this.seenCSVDwld = false;
      },

      actionChange(){
        if(this.phoneNum){
          this.currency='';
          this.amount='';
          this.reason='';
          var temp = this.baseItems2;
          var phoneNum = this.phoneNum;
          var user = this.baseUserId;
          let index = temp.findIndex(temp => temp === phoneNum );
          this.userId = user[index];
          var detail = this.getUserDetail(user[index]);
        } else {
          this.msg   = 'Please select phone number first !';
          this.color = 'warning'; this.seen  = true;
          setTimeout( () =>  this.seen=false , 5000);
        }
      },

      stringToBoolean: function(string){
          switch(String(string).toLowerCase().trim()){
              case "true": case "yes": case "1": return true;
              case "false": case "no": case "0": case null: return false;
              default: return Boolean(string);
          }
      },

      onReportTypeChange(event) {
          if (event == 'BALANCE_CHANGE') {
              this.visibleReport = true;
              this.currency = "CNY";
              this.feature = "CAPITAL";
          } else {
              this.visibleReport = false;
              this.currency = "";
              this.feature = "CAPITAL";
          }
      },

      onDateTypeChange(event) {
          var newDate = new Date();

          if (event == 'END_OF_DAY') {
              this.dateInputType = "date";
              this.hour = "";
              this.time = "";
              this.todayDate = newDate.toISOString().substr(0, 10);
              this.visibleIntra = false;
          } else if (event == "MONTHLY") {
              this.dateInputType = "month";
              this.hour = "";
              this.time = "";
              this.todayDate = newDate.toISOString().substr(0, 7);
              this.visibleIntra = false;
          } else if (event == "INTRA_DAY") {
              this.dateInputType = "date";
              this.visibleIntra = true;
              this.todayDate = newDate.toISOString().substr(0, 10);
              this.hour = "00";
              this.time = "00";
          }
      },

      onFileChange(event) {
        let xlsxfile = event.target.files ? event.target.files[0] : null;
        readXlsxFile(xlsxfile).then((rows) => {
            var temp = []; var names = ['phoneNumber','currency','amount'];
            rows.splice(0, 1);
						for (var key in rows) {
                let each = {};
                var row = rows[key];
                for (var key2 in row) {
                      var val = row[key2];
                      var name = names[key2];
                      each[name] = val;
											if(name == 'phoneNumber'){
													AuthService.getUsrDtlByPhone(val).then(resp => {
															console.log(resp);
															resp.wallets.forEach((item, index) => {
																if(this.walletGroupAvailable.includes(item.walletGroup.name) !== true){
																		this.walletGroupAvailable.push(item.walletGroup.name);
																}
															});
													}, error => {
															console.log(error);
													});
											}
                }
                temp.push(each);
            }
						this.items = temp;
        })
      },

      onModifycbbfile(){

         var checkFile = this.stringToBoolean(this.cbbfile);

         if(checkFile){
              //console.log('true');
              this.seenfile=true; this.isDisabledSlc=false; this.isDisabledfile = true;
              this.isDisabledcurr = false; this.isDisabledamount = false ;
              this.optWallet = this.walletGroup;
              this.phoneNum = ''; this.getWalletGroupList(); this.currency = ''; this.amount = 0;
              this.userId = ''; this.displayName = '';
              this.msg   = "Use excel file (*.xlsx)";
              this.color = 'info'; this.seen  = true;
              setTimeout( () =>  this.seen=false , 5000);
         } else {
             //console.log('false');
             this.seenfile=false;  this.isDisabledSlc=true; this.isDisabledfile = false;
             this.isDisabledcurr = true; this.isDisabledamount = true;
             this.cbbfile = false; this.text="";
             this.optWallet = [];    this.seenBtnDwld = false;
         }

      },

			sttimeChange() {
         var term = this.expiredTime; var temp = this.expiredTime;

         if (term.match(/HH|mm|ss/g)) {
            var temp = term.replace("HH", "00").replace("mm", "00").replace("ss", "00");
         }

         this.expiredTime = temp;

      },

      getUserDetail: function(id) {
					if(id !== undefined && id != "") {
			        AuthService.getUserDetail(id).then(resp => {  //console.log(resp);

			          var items = resp.wallets;
			          var wallets = [];
			          for (var key in items) {
			            var WalletId   = items[key].walletId;
			            var WalletName = items[key].walletGroup.name;
			            wallets.push({ value: WalletId  , text: WalletName });
			          }

			          var displayName = resp.firstName + " " + resp.lastName;
			          this.displayName = displayName.trim();
			          this.optWallet = wallets;
			          //this.optWallet = this.walletGroup;
								if(this.seenuser) this.seenuser=false;
			        }, error => {
			          this.loading = false;
								document.getElementById("phoneNumberSearch").style.border = "1px solid red";
			        });
					} else {
							document.getElementById("phoneNumberSearch").style.border = "1px solid red";
							this.coloruser="danger"; this.msguser = "User Not Found";
							this.seenuser=true;
							var dis = this;
							setTimeout( function(){ dis.seenuser=false; document.getElementById("phoneNumberSearch").style.removeProperty("border"); } , 5000);
					}
      },

      getWalletGroup: function(code) {
          AuthService.getWalletGroup(code).then(resp => { console.log(resp);

            //  this.groupUniqueNumber = resp.groupUniqueNumber;
            //  this.name = resp.name;
            //  this.follusergroup = resp.followUserGroup;

          }, error => {
            console.log('error');
          });
      },

      getWalletGroupList: function() {
        AuthService.getWalletGroupList().then(resp => {  //console.log(resp);

             var items = resp; var wallets = [];
             for (var key in items) {
                var WalletId   = items[key].id;
                var WalletName = items[key].name;
                wallets.push({ value: WalletId  , text: WalletName });
              }
              this.walletGroup = wallets;

        }, error => {
          this.loading = false;
        });
      },

			fetchUsers: function() {
        AuthService.getUserList({page:0, size:10000}).then(resp => { //console.log(resp);

        var users = resp.payload;
        var items = []; var items2 = []; var userId=[]; var basePhoneNum=[];

        for (var key in users) {
            //items2.push(users[key].mobileNumber.number);
            //userId.push(users[key].userIdentify.userId);
            var countryCode = users[key].mobileNumber.countryCode;
            var phoneNum = users[key].mobileNumber.number;
            var ccphoneNum = countryCode+phoneNum;   //console.log(ccphoneNum);
            items.push({ name : ccphoneNum});
            items2.push(ccphoneNum);
            userId.push(users[key].userIdentify.userId);
            basePhoneNum.push(ccphoneNum);
        }

        this.baseItems = items;
        this.baseUserId = userId;
        this.optNumber = items2;
        this.baseItems2 = items2;
        this.basePhoneNum = basePhoneNum;

        }, error => {
          this.loading = false;
        });
      },

			sortByProperty(property){
         return function(a,b){
            if(a[property] > b[property])
               return 1;
            else if(a[property] < b[property])
               return -1;
            return 0;
         }
      },

      pad(num, size) {
          var s = "000000000" + num;
          return s.substr(s.length-size);
      },

      getConfigList: function() {
          AuthService.getConfigList().then(resp => {   //console.log(resp);
            for (var hours=0; hours < 24; hours ++) {
                var realHour = this.pad(hours, 2);
                this.optHour.push({value: realHour, text: realHour});
            }
            var currSrc=[]; var temp = [];
            var arrcurrency = resp.currency;
            for (const item of arrcurrency){ temp.push(item); }
            temp.sort();
            for (var key in temp) {
                var curr = temp[key];
                if (curr == "SGD" || curr == "CNY" || curr == "USD") {
                  currSrc.push({ value: curr , text: curr });
                }
            }


            var typeSrc=[];
            var arrTypes = resp.systemTransactionTypes;
            for (const item2 of arrTypes) {
                if(item2 !== "REDEEM") { typeSrc.push({ value: item2 , text: item2 }); }
            }

            this.optTrType = typeSrc;

						var gFeatSrc=[]; var temp = []; var baseFeatures = [];
						var arr_notincludes = ['P2P_OUTSIDE_GROUP', 'P2P_OUTSIDE_COUNTRY', 'P2P_SEND_UNREG_USER', 'REQUEST_MONEY', 'P2M_PREFERRED_PARTNERS_ONLY', 'BY_INVITE', 'BY_REQUEST', 'TOPUPCARD', 'TOPUP_CHECKOUT', 'TOPUP_BANK', 'TOPUP_AUTO'];
						var groupFeatures = resp.groupFeatures;
						for (var key in groupFeatures) {
								var gFeat = groupFeatures[key];
								if(!arr_notincludes.includes(gFeat)) {
										gFeatSrc.push({ value: gFeat, text: gFeat });
								}
						}
						gFeatSrc.sort(this.sortByProperty("value"));


           }, error => {
            console.log('error');
          });
      },

    }
  }
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style src="vue2-timepicker/dist/VueTimepicker.css"></style>
<style>
/* .list-group shadow vbt-autcomplete-list {  background: black; } */
[role~=listbox]{ background: black; }
</style>
